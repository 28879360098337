.banner {
    @include flex-banner;
    width: 100%;
    height:285px;
    background-color: $secondary-color;

    @media screen and (max-width: 810px) {
        & {
            @include flex-column;
            margin-top: 0%;
        }
      }


    &__titre {
        font-size:500%;
        font-weight: bold;
        color:$tertiary-color;

        @media screen and (max-width: 810px) {
            & {
                font-size:300%;
                margin: .5em;
            }
          }
    
          @media screen and (max-width: 640px) {
            & {
                font-size:280%;
            }
          }
    
          @media screen and (max-width: 440px) {
            & {
                font-size:230%;
            }
          }
    }
    &__stitre {
        font-size:400%;
        font-weight:bold;
        color:$tertiary-color;
        font-family: 'Tangerine', serif;
        margin:2%;

        @media screen and (max-width: 810px) {
            & {
                font-size:280%;
            }
          }
    
          @media screen and (max-width: 640px) {
            & {
                font-size:260%;
                color: $tertiary-color;
            }
          }
    
          @media screen and (max-width: 440px) {
            & {
                font-size:190%;
                margin:5%;
            }
          }
    }
}

.navbar {
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    align-items:flex-start;
    margin:2%;
    font-family: 'Tangerine', serif;
    color:$tertiary-color;
    &__link {
      font-size: 300%;
      text-decoration: none;
      margin: 0% 3% 0% 3%;
      text-align: center;
      &:hover {
        text-decoration: overline;
      }
      @media screen and (max-width: 810px) {
        & {
            font-size:280%;
            &:hover {
              text-decoration: overline;
            }
        }
      }
      @media screen and (max-width: 640px) {
        & {
            font-size:260%;
            &:hover {
              text-decoration: overline;
            }
        }
      }
      @media screen and (max-width: 440px) {
        & {
            font-size:180%;
            margin: 0% 1% 0% 1%;
            &:hover {
              text-decoration: overline;
            }
        }
      }
      @media screen and (max-width: 390px) {
        & {
            font-size:160%;
            margin: 0% 3.5% 0% 3.5%;
            &:hover {
              text-decoration: overline;
            }
        }
      }
      @media screen and (max-width: 360px) {
        & {
            font-size:150%;
            margin: 0% 4.5% 0% 3.5%;
            &:hover {
              text-decoration: overline;
            }
        }
      }
    }
    }


