.Projet__formations, .Projet__web, .Projet__aide {
  margin: 0% 5% 10% 5%;
    @media screen and (max-width: 360px) {
      & {
        margin: 0% 6% 10% 3%;
      }
    }
  &__title {
    font-size:400%;
    font-family: 'Tangerine', serif;
    text-decoration: underline;
    @media screen and (max-width: 810px) {
      & {
         font-size:350%;
      }
    }
    @media screen and (max-width: 640px) {
      & {
          font-size:330%;
      }
    }
    @media screen and (max-width: 440px) {
      & {
          font-size:250%;
          margin-top: 10%;
      }
    }
  }
  &__title2 {
    font-size:200%;
    color:$primary-color;
    font-weight:normal;
    text-align:center;
    margin-top: 2em;
    margin-bottom:1em;
    &::before {
      font-size: 200%;
      content:'✓ ';
    }
    @media screen and (max-width: 810px) {
      & {
         font-size:180%;
      }
    }
    @media screen and (max-width: 640px) {
      & {
          font-size:170%;
      }
    }
    @media screen and (max-width: 440px) {
      & {
          font-size:150%;
      }
    }
    @media screen and (max-width: 360px) {
      & {
          font-size:130%;
      }
    }
  }
  .blancTitleTop {
    margin-top:8em;
  }
  &__liste__alinea {
    font-size: 180%;
    text-align: justify;
    margin-left: 5%;
    margin-bottom: 3px;
    @media screen and (max-width: 810px) {
      & {
        font-size: 170%;
      }
    }
    @media screen and (max-width: 800px) {
      & {
        font-size: 165%;
      }
    }
    @media screen and (max-width: 640px) {
      & {
        font-size: 150%;
        text-indent: 0em;
      }
    }
    @media screen and (max-width: 440px) {
      & {
        font-size: 140%;
      }
    }
    @media screen and (max-width: 360px) {
      & {
        font-size: 118%;
      }
    }
  }
  &__alinea {
    font-size: 180%;
    text-align: justify;
    text-indent: 1em;
    margin-bottom: 3px;
    @media screen and (max-width: 810px) {
      & {
        font-size: 170%;
      }
    }
    @media screen and (max-width: 800px) {
      & {
        font-size: 165%;
      }
    }
    @media screen and (max-width: 640px) {
      & {
        font-size: 150%;
        text-indent: 0em;
      }
    }
    @media screen and (max-width: 440px) {
      & {
        font-size: 140%;
      }
    }
    @media screen and (max-width: 360px) {
      & {
        font-size: 118%;
      }
    }
      &.blancTop {
        margin-top: 2em;
      }
    }
    &.blancTop {
      margin-top: 10em;
    }
    
    &__container {
      background-color: $tertiary-color;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 3%;
      @media screen and (max-width: 640px) {
        & {
          flex-direction: column;
        }
      }
      img {
        padding: 10%;
      }
      &__remer {
        font-size: 180%;
        text-align:right;
        text-indent: 1em;
        margin:2% 20% 0% 20%;
        @media screen and (max-width: 810px) {
          & {
            font-size: 170%;
          }
        }
        @media screen and (max-width: 800px) {
          & {
            font-size: 165%;
          }
        }
        @media screen and (max-width: 640px) {
          & {
            font-size: 150%;
            text-indent: 0em;
          }
        }
        @media screen and (max-width: 440px) {
          & {
            font-size: 140%;
            margin: 3% 5% 0% 2%;
          }
        }
        @media screen and (max-width: 360px) {
          & {
            font-size: 118%;
            margin:4% 8% 2% 4%;
          }
        }
        &.right {
          margin-bottom: 10%;
        }
      }
      &__sign {
        font-size: 300%;
        margin:1% 2% 3% 2%;
                @media screen and (max-width: 810px) {
          & {
            font-size: 250%;
          }
        }
        @media screen and (max-width: 640px) {
          & {
            font-size: 150%;
          }
        }
      }
    }
  }

  