.presentation {
    margin: 0% 5% 10% 5%;
    @media screen and (max-width: 360px) {
      & {
        margin: 0% 6% 10% 3%;
      }
    }

    &__title {
      font-size: 400%;
      font-family: 'Tangerine', serif;
      font-weight: bold;
      text-align: center;
      margin-top: 2em;
      margin-bottom: 1em;
      @media screen and (max-width: 810px) {
        & {
          font-size: 300%;
        }
      }
    }
    &__stitle {
      font-size: 400%;
      font-family: 'Tangerine', serif;
      font-weight: bold;
      text-align: center;
      margin-top: 2em;
      margin-bottom: 1em;
      @media screen and (max-width: 810px) {
        & {
          font-size: 300%;
        }
      }
    }
    &__alinea {
      font-size: 180%;
      text-align: justify;
      text-indent: 1em;
      margin-bottom: 3px;
      @media screen and (max-width: 810px) {
        & {
          font-size: 170%;
        }
      }
      @media screen and (max-width: 800px) {
        & {
          font-size: 165%;
        }
      }
      @media screen and (max-width: 640px) {
        & {
          font-size: 150%;
          text-indent: 0em;
        }
      }
      @media screen and (max-width: 440px) {
        & {
          font-size: 140%;
        }
      }
      @media screen and (max-width: 360px) {
        & {
          font-size: 118%;
        }
      }
      &.blancTop {
        margin-top: 2em;
      }
    }
    &__lst__item {
      font-size: 180%;
      text-align: justify;
      margin-left: 5%;
      margin-bottom: 3px;
      @media screen and (max-width: 810px) {
        & {
          font-size: 170%;
        }
      }
      @media screen and (max-width: 800px) {
        & {
          font-size: 165%;
        }
      }
      @media screen and (max-width: 640px) {
        & {
          font-size: 150%;
          text-indent: 0em;
        }
      }
      @media screen and (max-width: 440px) {
        & {
          font-size: 140%;
        }
      }
      @media screen and (max-width: 360px) {
        & {
          font-size: 118%;
        }
      }
    }
    & .color__services {
      color:$service-color;
      font-weight: bold;
    }
}


  


  