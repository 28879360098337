@import url("https://fonts.googleapis.com/css?family=Tangerine");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  @include body-position;
  @media (min-width: $breakpoint-desktop) {
    max-width: 100%;
  }
}

main {
  @media screen and (min-width: $breakpoint-desktop) {
    margin: 0;
  }
}


.button {
  font-size: 140%;
  background-color: $primary-color;
  border-radius: 4px;
  padding: 10px;
  color: #fff;
  &:hover {
    background-color: darken($primary-color, 10%);
  }
  &:active {
    position: relative;
    outline: none;
    top: 1px;
  }
}

.alinea__center {
  text-align: center;
  margin:5%;
  @media screen and (max-width: 440px) {
    & {
      margin-top:15%;
      margin-bottom: 15%;
    }
  }
}

.times {
  font-size: 50%;
  font-family:'Times New Roman', Times, serif;
  font-style: italic;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.container__pdf__cv {
  text-align: center;
}