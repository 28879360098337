//Les mixines
@mixin body-position {
    max-width: $wmax;
    min-width: $wmin;
    margin: auto;
  }
  
  @mixin linear-gradient {
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, #00000080);
  }
  
  @mixin WH-full {
    width: 100%;
    height: 100%;
  }
  
  @mixin font-right($size) {
    font-size: $size;
    font-style: normal;
    font-weight: 500;
    text-align: right;
    line-height: 142.6%;
  }
  
  @mixin font($size, $weight) {
    font-size: ($size);
    font-style: normal;
    font-weight: $weight;
    line-height: 142.6%;
  }

  @mixin flex-banner {
    position: relative;
    @include flex-column;
    width: 100%;
    text-align: center;
  }
  
  @mixin bkgrd($background) {
    background: $background;
    background-blend-mode: darken;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @mixin flex-around {
    display: flex;
    align-items:center;
    justify-content:space-around;
    text-align: center;
  }

  @mixin flex-start {
    display: flex;
    align-items:center;
    justify-content:flex-start;
    text-align:left;
  }
  
  @mixin flex-center {
    display: flex;
    align-items: center;
    justify-content:center;
    text-align: center;
  }
  
  @mixin flex-column {
    display: flex;
    flex-direction: column;
    align-items:stretch;
    justify-content: center;
  }

  @mixin flex-row {
    display: flex;
    flex-direction:row;
    align-items:flex-start;
    justify-content:flex-start;
  }
  
  
  @mixin flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  
  @mixin media-query($key) {
    $size: map-get($breakpoints, $key);
    @media only screen and (min-width: $size) {
      @content;
    }
  }

  @mixin animation_color {
    @keyframes acolor {
      0% {color: $black-color;}
      25% {color: $black-color;}
      50% {color: $black-color;}
      75% {color: $white-color;}
      100% {color: $white-color;}      
      }
  }

  @mixin animation_around {
    @keyframes cacheround {
    0% {border:$black-color; color: $black-color; box-shadow: inset $black-color 400px 400px 400px 400px;}
    25% {border:$black-color; color: $black-color; box-shadow: inset $primary-color 300px 300px 300px 300px;}
    50% {border:$black-color; color: $black-color; box-shadow: inset $secondary-color 200px 200px 200px 200px;}
    75% {border:$black-color; color: $white-color; box-shadow: inset $fourth-color 100px 100px 100px 100px;}
    100% {border:$black-color; color: $white-color; box-shadow: inset $fifth-color 50px 50px 50px 50px;}      
    }
  }