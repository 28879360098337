.Projet {
  margin: 0% 5% 10% 5%;
  @media screen and (max-width: 360px) {
    & {
      margin: 0% 6% 10% 3%;
    }
  }
  &__title {
    font-size:400%;
    font-family: 'Tangerine', serif;
    text-decoration: underline;
    @media screen and (max-width: 810px) {
      & {
         font-size:350%;
      }
    }
    @media screen and (max-width: 640px) {
      & {
          font-size:330%;
      }
    }
    @media screen and (max-width: 440px) {
      & {
          font-size:250%;
          margin-top: 10%;
      }
    }
  }
  &__title2 {
    font-size:200%;
    color:$primary-color;
    font-weight:normal;
    text-align:center;
    margin-top: 2em;
    margin-bottom: 2em;
    &::before {
      font-size: 200%;
      content:'✓ ';
    }
    @media screen and (max-width: 810px) {
      & {
         font-size:180%;
      }
    }
    @media screen and (max-width: 640px) {
      & {
          font-size:170%;
      }
    }
    @media screen and (max-width: 440px) {
      & {
          font-size:150%;
      }
    }
    @media screen and (max-width: 360px) {
      & {
          font-size:130%;
      }
    }
  }
  &__alinea {
    font-size: 180%;
    text-align: justify;
    text-indent: 1em;
    margin-bottom: 3px;
    @media screen and (max-width: 810px) {
      & {
        font-size: 170%;
      }
    }
    @media screen and (max-width: 800px) {
      & {
        font-size: 165%;
      }
    }
    @media screen and (max-width: 640px) {
      & {
        font-size: 150%;
        text-indent: 0em;
      }
    }
    @media screen and (max-width: 440px) {
      & {
        font-size: 140%;
      }
    }
    @media screen and (max-width: 360px) {
      & {
        font-size: 118%;
      }
    }
  &__center {
    font-size: 180%;
    margin-top: 4em;
    text-align: center;
    margin-bottom: 3px;
    & a {
      text-decoration: none;
    }
    @media screen and (max-width: 810px) {
      & {
         font-size:170%;
      }
    }
    @media screen and (max-width: 640px) {
      & {
          font-size:160%;
      }
    }
    @media screen and (max-width: 440px) {
      & {
          font-size:150%;
          margin-top: 1em;
          margin-bottom: 1em;
      }
    }
    @media screen and (max-width: 360px) {
      & {
          font-size:125%;
          margin-top: 2em;
          margin-bottom: 1em;
      }
    }
   }
  }
  &__retour {
    font-size: 180%;
    text-align: justify;
    text-indent: 1em;
    margin-bottom: 3px;
    @media screen and (max-width: 810px) {
      & {
        font-size: 170%;
      }
    }
    @media screen and (max-width: 800px) {
      & {
        font-size: 165%;
      }
    }
    @media screen and (max-width: 640px) {
      & {
        font-size: 150%;
        text-indent: 0em;
      }
    }
    @media screen and (max-width: 440px) {
      & {
        font-size: 140%;
      }
    }
    @media screen and (max-width: 360px) {
      & {
        font-size: 118%;
      }
    }
  }
  &__nav {
    width:100%;
    height:50%;
    display: flex;
    flex-direction:row;
    align-content:space-between;
    padding-bottom:0;
    background-color: $tertiary-color;
    margin: 0;
    @media screen and (max-width: 810px) {
      & {
        flex-direction:column;
        align-content: center;
        align-items: center;
      }
    }
    @media screen and (max-width: 360px) {
      & {
        width:96%;
        align-content: center;
        align-items: center;
      }
    }
    &__link {
      text-decoration: none;
      color: #000000;
      width:90%;
      height:auto;
      display: flex;
      border: $white-color 5px solid;
      border-radius:20px;
      flex-direction: column;
      background-color: $white-color;
      padding:3px;
      margin:20px;
      @media screen and (max-width: 810px) {
        & {
          width: 95%;
        }
      }
      @media screen and (max-width: 640px) {
        & {
          width: 94%;
        }
      }
      @media screen and (max-width: 440px) {
        & {
          width: 90%;
        }
      }
      & img { 
        width: 100%;
        height:100%;
        border-top-left-radius:20px;
        border-top-right-radius:20px;
        box-shadow: 5px 5px 5px 5px $secondary-color;
        object-fit:cover;
       }
     }
  }
  &__photo {
    width: 100%;
    height:160px;
    margin-top:0;
    margin-bottom:0;
    padding: 0;
   }
  &__stitle {
   font-size:115%;
   font-weight: bold;
   margin-top: .5em;
   text-align: center;
   text-decoration: underline;
  }
  &__description {
   font-size:115%;
   text-align:justify;
   margin-left: 3px;
   margin-right: 3px;
   margin-top:20px;
   margin-bottom:0;
  }
}


 
.Container__projet {
  margin: 0% 5% 10% 5%;
  @media screen and (max-width: 360px) {
    & {
      margin: 0% 6% 10% 3%;
    }
  }
  &__title {
    font-size: 400%;
    font-family: 'Tangerine', serif;
    font-weight: bold;
    text-align: center;
    color:$primary-color;
    margin-top: 2em;
    margin-bottom: 1em;
    @media screen and (max-width: 810px) {
      & {
         font-size:350%;
      }
    }
    @media screen and (max-width: 640px) {
      & {
          font-size:330%;
      }
    }
    @media screen and (max-width: 440px) {
      & {
          font-size:250%;
      }
    }
  }
  &__stitle {
    font-size: 180%;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: bold;
    text-align: center;
    color:$primary-color;
    margin-top: 2em;
    margin-bottom: 1em;
    @media screen and (max-width: 810px) {
      & {
        font-size: 170%;
      }
    }
    @media screen and (max-width: 800px) {
      & {
        font-size: 165%;
      }
    }
    @media screen and (max-width: 640px) {
      & {
        font-size: 150%;
      }
    }
    @media screen and (max-width: 440px) {
      & {
        font-size: 140%;
      }
    }
    @media screen and (max-width: 360px) {
      & {
        font-size: 130%;
      }
    }
  }
  &__liste__alinea {
    font-size: 180%;
    text-align: justify;
    margin-left: 5%;
    margin-bottom: 3px;
    @media screen and (max-width: 810px) {
      & {
        font-size: 170%;
      }
    }
    @media screen and (max-width: 800px) {
      & {
        font-size: 165%;
      }
    }
    @media screen and (max-width: 640px) {
      & {
        font-size: 150%;
        text-indent: 0em;
      }
    }
    @media screen and (max-width: 440px) {
      & {
        font-size: 140%;
      }
    }
    @media screen and (max-width: 360px) {
      & {
        font-size: 118%;
      }
    }
  }
  &__sliste__alinea {
    margin-left: 3%;
    margin-bottom: 3px;
    @media screen and (max-width: 440px) {
      & {
        margin-left: 2%;
      }
    }
  }
  &__alinea {
    font-size: 180%;
    text-align: justify;
    text-indent: 1em;
    margin-bottom: 3px;
    @media screen and (max-width: 810px) {
      & {
        font-size: 170%;
      }
    }
    @media screen and (max-width: 800px) {
      & {
        font-size: 165%;
      }
    }
    @media screen and (max-width: 640px) {
      & {
        font-size: 150%;
        text-indent: 0em;
      }
    }
    @media screen and (max-width: 440px) {
      & {
        font-size: 140%;
      }
    }
    @media screen and (max-width: 360px) {
      & {
        font-size: 118%;
      }
    }
  }
}



* {
  box-sizing: border-box;
}

.App {
  max-width: 800px;
  margin:0 auto;
  @media screen and (max-width: 810px) {
    & {
      max-width: 750px;
    }
  }
  @media screen and (max-width: 800px) {
    & {
      max-width:  700px;
    }
  }
  @media screen and (max-width: 640px) {
    & {
      max-width: 600px;
    }
  }
  @media screen and (max-width: 440px) {
    & {
      max-width:  400px;
    }
  }
  @media screen and (max-width: 360px) {
    & {
      max-width:  300px;
    }
  }
}

.rapport__opt {
  text-align: center;
  margin: 5% 20% 0% 20%;
  @media screen and (max-width: 810px) {
    & {
      width: 95%;
    }
  }
  @media screen and (max-width: 640px) {
    & {
      width: 94%;
    }
  }
  @media screen and (max-width: 440px) {
    & {
      width: 98%;
      margin: 5% 2% 0% 2%;
    }
  }
}

.Container__book {
  @media screen and (max-width: 810px) {
    & {
      visibility: hidden;
    }
  }
}

#monpopo2 {
    margin-left:40%;
    margin-top:5%;
    @media screen and (max-width: 810px) {
      & {
        margin-left:3%;
        margin-right:6%;
        margin-top:3%;
      }
    }
  }

  .monpopo2 {
    @media screen and (max-width: 810px) {
      & {
        margin-left:3%;
        margin-right:6%;
        margin-top:20%;
      }
    }
  }


#flip {
    animation: wrapper 6s ease-in-out infinite;
    height: 350px;
    width: 253px; 
    position: absolute;
    left: 20%;
    top: 80%;
    transform: translateZ(-10px) rotateX(60deg) rotateZ(29deg) rotateY(0deg);
    transform-style: preserve-3d;
    transform-origin: 0 0 0;
  }
  @keyframes wrapper {
    50% {
      transform: translateZ(-10px) rotateX(60deg) rotateZ(29deg) rotateY(-180deg);
    }
  }
  #flip div {
    height: 350px;
    width: 24px;
    position: absolute;
    left: calc(100% - 1px);
    transform-origin: 0 100%;
    transform-style: preserve-3d;
    background-size: 253px 350px;
  }
  #flip #front, 
  #flip #front div {
    background-image: url(../../assets/livre_couv.webp);
    box-shadow: inset rgba(255,255,255,0.3) 0px -1px 0 0,#35582C 0px 1px 0px 0px;
  }
  #flip #front > div > div > div > div > div > div > div > div > div > div { 
    box-shadow: inset rgba(255,255,255,0.3) -1px -1px 0 0, #35582C 1px 1px 0px 0px;
  }
  #flip #back { transform: rotateY(.4deg); transform-origin: -100% 0; } /* avoid pages overlap */
  #flip #back,
  #flip #back div {
    background-image: url(../../assets/livre_int.webp); 
  }
  
  #flip > div {  left: 0;  background-position-x: 0; }
  #flip div > div { background-position-x: -23px; animation: page 6s ease-in-out infinite; }
  #flip div > div > div { background-position-x : -46px; } 
  #flip div > div > div > div { background-position-x : -69px; }
  #flip div > div > div > div > div { background-position-x : -92px; }
  #flip div > div > div > div > div > div { background-position-x : -115px; }
  #flip div > div > div > div > div > div > div { background-position-x : -138px; }
  #flip div > div > div > div > div > div > div > div  { background-position-x : -161px; }
  #flip div > div > div > div > div > div > div > div > div { background-position-x : -184px; }
  #flip div > div > div > div > div > div > div > div > div > div { background-position-x : -207px; }
  #flip div > div > div > div > div > div > div > div > div > div > div { background-position-x : -229px; }
  /* the more pieces you have, the smoother the bend is */
  
  @keyframes page {
    15% { transform: rotateY(-10deg); }
    50% { transform: rotateY(-2deg); }
    65% { transform: rotateY(10deg); }
    100% { transform: rotateY(0deg); }
  }
  
  #book {
    width: 248px;
    height: 350px;
    position: absolute;
    left:20%;
    top: 80%;
    transform: translate3d(0px,0px,-10px) rotateX(60deg) rotateZ(29deg);
    transform-style: preserve-3d;
    -webkit-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
  }
  @keyframes book {
    25% {
      box-shadow: inset rgba(0,0,0,.2) 0px 0 50px -140px;
    }
    50% {
      box-shadow: inset rgba(0,0,0,.2) 0px 0 50px -140px;
    }
    100% {
      box-shadow: inset rgba(0,0,0,.2) 510px 0 50px -140px;
    }
  }
  #book #top {
    animation: book 6s ease-in-out infinite;
    background: url(../../assets/livre_int2.webp);
    background-size: 100% 100%;
    background-position: 100%;
    box-shadow: inset rgba(0,0,0,0.2) 510px 0 50px -140px;
    height: 350px;
    width: 248px;
    position: absolute;
    left: 0;
    top: 0;
  }
  #book #bottom {
    background: #E7DED1;
    box-shadow: rgba(83, 53, 13, 0.2) 4px 2px 1px,
                #35582C 1px 1px 0px 0px;
    height: 350px;
    width: 253px;
    position: absolute;
    transform: translateZ(-40px);
    left: 0;
    top: 0;
  }
  #book #shadow {
    animation: shadow 6s ease-in-out infinite;
    box-shadow: inset rgba(83, 53, 13, 0) -200px 0 150px -140px;
    height: 350px;
    width: 248px;
    position: absolute;
    left: -100%;
    top: 0;
    transform: translateZ(-40px);
  }
  @keyframes shadow {
    20% {
      box-shadow: inset rgba(83, 53, 13, 0) -200px 0 150px -140px;
    }
    50% { 
      box-shadow: inset rgba(83, 53, 13, 0.3) -350px 0 150px -140px;
    }
    60% { 
      box-shadow: inset rgba(83, 53, 13, 0) -200px 0 150px -140px;
    }
  }
  #book #front {
    background: -webkit-linear-gradient(top,#FCF6EA, #D8D1C3);
    background-size: 100% 2px; 
    box-shadow: inset #C2BBA2 3px 0 0px, #35582C -2px 1px 0px 0px;
    height: 40px;
    width: 251px;
      left: -3px;
    position: absolute;
    bottom: -40px;
    transform: rotateX(-90deg);
    transform-origin: 50% 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  #book #right {
    background: -webkit-linear-gradient(left,#DDD2BB, #BDB3A0);
    background-size: 2px 100%; 
    box-shadow: inset rgba(0,0,0,0) 0 0 0 20px;
    height: 100%;
    width: 40px;
    position: absolute;
    right: -40px;
    top: 0;
    transform: rotateY(90deg);
    transform-origin: 0 50%;
  }

  
  /* bookmark */
  
  #bookmark {
    position: absolute;
    transform: translate3d(20px,350px,-16px);
    transform-style: preserve-3d;
  }
  #bookmark div {
    background: rgb(151, 88, 88);
    box-shadow: rgb(133,77,77) 1px 0;
    height: 10px;
    width: 20px;
    position: absolute;
    top: 9px;
    transform: rotateX(-14deg);
    transform-origin: 50% 0;
    transform-style: preserve-3d;
  }
  #bookmark > div > div {
    background: linear-gradient(to bottom, rgb(151, 88, 88), rgb(189, 123, 123), rgb(151, 88, 88));
  }
  #bookmark > div > div > div {
    background: linear-gradient(to bottom,rgb(151, 88, 88),rgb(133, 77, 77));
  }
  #bookmark > div > div > div > div {
    background: none;
    border-top: 0px solid transparent;
    border-right: 10px solid rgb(133, 77, 77);
    border-bottom: 10px solid transparent;
    border-left: 10px solid rgb(133, 77, 77);
    height: 0;
    width: 0;
  }
  #bookmark-shadow {
    background: linear-gradient(to bottom,rgba(83, 53, 13, 0.25),rgba(83, 53, 13, 0.11));
    height: 15px;
    width: 20px;
    position: absolute;
    transform: translate3d(12px,350px,-25px) rotateX(-90deg) skewX(20deg);
    transform-origin: 0 0;
  }