.footer {
    width: 100%;
    height:auto;
    background-color:$fifth-color;
    padding: 3%;
    @include flex-start;
    justify-content: space-around;
    &__titre {
        font-size: 100%;
        color:$white-color;
        margin-bottom: 6px;
        @media screen and (max-width: 810px) {
            & {
               font-size:90%;
            }
          }
          @media screen and (max-width: 640px) {
            & {
                font-size:80%;
            }
          }
          @media screen and (max-width: 440px) {
            & {
                font-size:70%;
            }
          }
    }
    &__stitre {
        font-size: 100%;
        color:$white-color;
        margin-bottom: 6px;
        @media screen and (max-width: 810px) {
            & {
               font-size:90%;
            }
          }
          @media screen and (max-width: 640px) {
            & {
                font-size:80%;
            }
          }
          @media screen and (max-width: 440px) {
            & {
                font-size:70%;
            }
          }
    }
    &__copyr {
        font-size: 100%;
        color:$white-color;
        margin-bottom: 6px;
        @media screen and (max-width: 810px) {
            & {
               font-size:90%;
            }
          }
          @media screen and (max-width: 640px) {
            & {
                font-size:80%;
            }
          }
          @media screen and (max-width: 440px) {
            & {
                font-size:70%;
            }
          }
            a {
            color: $white-color;
            text-decoration: none;
            }
        }
      &__raiseaux {
        font-size: 100%;
        color:$white-color;
        margin-bottom: 6px;
        @media screen and (max-width: 810px) {
            & {
               font-size:90%;
            }
          }
          @media screen and (max-width: 640px) {
            & {
                font-size:80%;
            }
          }
          @media screen and (max-width: 440px) {
            & {
                font-size:70%;
            }
          }
            a {
            color: $white-color;
            text-decoration: none;
            }
            .IconeLinkedin {
              font-size: 200%;
            }
        }
    &__navbar {
        @include flex-column;
        
        &__link {
            font-size: 100%;
            color:$white-color;
            margin-bottom: 6px;
            text-decoration: none;
            @media screen and (max-width: 810px) {
                & {
                    font-size: 90%;
                }
              }
        
              @media screen and (max-width: 640px) {
                & {
                    font-size: 80%;
                }
              }
        
              @media screen and (max-width: 440px) {
                & {
                    font-size: 70%;
                }
              }
        }
    }
}