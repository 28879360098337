.errorContainer {
    text-align: center;
    margin:15% 5% 15% 5%;

    &__title {
      font-size:230%;
      color: $primary-color;

      @media screen and (max-width: 810px) {
        & {
          margin:15% 20% 15% 20%;
        }
      }

      @media screen and (max-width: 640px) {
        & {
          margin:15% 3% 15% 3%;
        }
      }
    }
    &__button__Link {
      color: $white-color;
    }
    & .button {
      color: $white-color;
      margin-top: 3em;
    }
  }