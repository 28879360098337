//Les variables

$primary-color: #2f435e;
$secondary-color: #44566c;
$tertiary-color:#ececec;
$fourth-color:  #4b5e65;
$fifth-color: #201b22;
$white-color:#ffffff;
$black-color:#000000;
$service-color:rgb(254, 7, 135);

$breakpoints: (
  small: 350px,
  medium: 768px,
  large: 992px,
  xlarge: 1200px,
);

$breakpoint-desktop: 769px;

$wmin: 375px;
$wmax: 768px;