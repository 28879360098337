.contact__title {
    font-size:400%;
    font-family: 'Tangerine', serif;
    margin:6% 2% 4% 2%;
    text-decoration: underline;
    @media screen and (max-width: 810px) {
      & {
         font-size:350%;
      }
    }
    @media screen and (max-width: 640px) {
      & {
          font-size:330%;
      }
    }
    @media screen and (max-width: 440px) {
      & {
          font-size:250%;
          margin-top: 10%;
      }
    }
}


.container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom:5%;
  }
  
  form {
    width:65%;
    display: flex;
    flex-direction: column;
    margin:2% 2% 4% 2%;
  }
  
  label {
    font-size: 140%;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  input {
    padding: 5px;
    font-size: 140%;
    border-width: 1px;
    border-color: #cccccc;
    background-color: #ffffff;
    color: #000000;
    border-style: solid;
    border-radius: 0px;
    box-shadow: 0px 0px 5px rgba(66, 66, 66, 0.75);
  }
  
  textarea {
    padding:5px;
    height: 500px;
    font-size: 140%;
    border-width: 1px;
    border-color: #cccccc;
    background-color: #ffffff;
    color: #000000;
    border-style: solid;
    border-radius: 0px;
    box-shadow: 0px 0px 5px rgba(66, 66, 66, 0.75);
  }

  
  input:focus {
    outline: none;
  }

  input:invalid {
    color: #f90b0b;
  }
  
  input:valid {
    color: #156815;
  }
  
  input:required {
    color: #f90b0b;
  }

  .required {
    color: #f90b0b;
  }
  
  input:required:valid {
    color: #156815;
  }
  
  textarea:focus {
    outline: none;
  }

  .button {
    cursor: pointer;
    &:after {
      content: " >>>";
    }
    
    &:hover,
    &:focus {
      outline: none;
      background: #000;
      color: #fff;
    }
  }
  
  